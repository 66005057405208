// ======= Helper =======
// Display
// == Flex behaviors ==
.flexno {
    flex: none !important;
}

.flex0 {
    flex: 0 !important;
}

.flex1 {
    flex: 1 !important;
}

.flex-auto {
    flex: auto !important;
}
// .flex1-auto {
//   flex: 1 1 auto !important;
// }
// END Flex
// END == Flex behaviors ==

// Grid
.d-grid {
    display: grid !important;
}
// END Grid

// END Display

// Typography
// .lh-inh
.before-lh-inh::before {
    line-height: inherit;
}

.lh-normal {
    line-height: normal;
}

.lh-1 {
    line-height: 1;
}

.lh-1-25 {
    line-height: 1.25;
}

.lh-1-5 {
    line-height: 1.5;
}

.ws-pre {
    white-space: pre;
}

// .pre-wrap-before:before, // pre-wrap / pre-line for :before
/*.pre-wrap {
  white-space: pre-wrap;
}*/

.ws-normal {
    white-space: normal;
}

.txt-outline-fff {
    text-shadow: 0.02em 0.02em 0 #fff, -0.02em -0.02em 0 #fff, -0.02em 0.02em 0 #fff, 0.02em -0.02em 0 #fff, 0 0.02em 0 #fff, 0 -0.02em 0 #fff, -0.02em 0 0 #fff, 0.02em 0 0 #fff;
}
// END Typography

// == Sizing ==
.mxw-fit {
    max-width: fit-content !important;
}

// For .btn medium with fa equal width
.w-34px {
    width: 34px;
}

.h-70vh {
    height: 70vh;
}

.h-75vh {
    height: 75vh;
}

.h-85vh {
    height: 85vh;
}

.min-h-100 {
    min-height: 100%;
}

.min-h-75vh {
    min-height: 75vh;
}

.max-h-75vh {
    max-height: 75vh;
}

// For main content metronic
.min-h-content {
    min-height: calc(100vh - 165px);
}

.min-h-160px {
    min-height: 160px;
}

// .h-inherit {
//   height: inherit;
// }

.mnw-auto {
    min-width: auto;
}

.mnw-80px {
    min-width: 80px;
}

.mxh-50vh {
    max-height: 50vh;
}

.mxh-200px {
    max-height: 200px;
}
// == END Sizing ==

// == Spacing ==
.pr-5px {
    padding-right: 5px;
}

// Temporary solution for Sticky, tembus
.px-1px {
    padding-right: 1px;
    padding-left: 1px;
}

.ml-1px-next > * + * {
    margin-left: 1px !important;
}

.ml-1-next > * + * {
    margin-left: .25rem;
}

.ml-2-next > * + * {
    margin-left: .5rem;
}

.ml-3-next > * + * {
    margin-left: 1rem;
}

.ml-4-next > * + * {
    margin-left: 1.5rem;
}

.mt-1-next > * + * {
    margin-top: .25rem; //  !important
}

.mt-2-next > * + * {
    margin-top: .5rem !important;
}

.mt-3-next > * + * {
    margin-top: 1rem;
}

.mt-2rem-next > * + * {
    margin-top: 2rem;
}

// .mt--1-next > * + *,
.mt-n1px {
    margin-top: -1px;
}

// *.mx-min15 {
//   margin-right: -15px;
//   margin-left: -15px;
// }

.mx-min25 {
    margin-right: -25px;
    margin-left: -25px;
}
// == END Spacing ==

.react-draggable-dragging:after, // OPTION
.select-no {
    user-select: none !important;
}

.select-auto {
    user-select: auto !important;
}

// .ovunset {
//   overflow: unset !important;
// }

.ovauto {
    overflow: auto !important;
}

.ovxhide {
    overflow-x: hidden !important;
}

.ovyhide {
    overflow-y: hidden !important;
}

// .ant-picker-time-panel-column,
.ovyauto {
    overflow-y: auto !important;
}

.ovxauto {
    overflow-x: auto !important;
}
// .ovvisible{
//   overflow:visible !important;
// }
// .react-kanban-board,
.ovscroll,
.ovxscroll {
    overflow-x: scroll !important;
}

.ovscroll,
.ovyscroll {
    overflow-y: scroll !important;
}

.ovhide {
    overflow: hidden !important;
}
// == Border ==
.bw-y1 {
    border-width: 1px 0 !important;
}

.bw-x1 {
    border-width: 0 1px !important;
}

.round-top {
    border-radius: .25rem .25rem 0 0 !important;
}

/* .round-bottom {
  border-radius: 0 0 .25rem .25rem !important;
}*/

.round-left-0,
.round-tl-0 {
    border-top-left-radius: 0;
}

.round-right-0,
.round-br-0 {
    border-bottom-right-radius: 0 !important;
}

.round-left-0,
.round-bl-0 {
    border-bottom-left-radius: 0 !important;
}

.round-right-0 {
    border-top-right-radius: 0;
}

.round-tr-1 {
    border-top-right-radius: 1rem;
}

/* .resize-v {
  resize: vertical;
}

.resize-no {
  resize: none;
} */

// == Color ==
.txt-inh {
    color: inherit;
}

.txt-navy {
    color: #0059a8;
}

.bg-eee {
    background-color: #eee !important;
}

.bg-strip {
    background: repeating-linear-gradient(-45deg,#ddd,#ddd 1px,#fff 3px,#fff 3px);
}

.bg-info-bs5 {
    background-color: #2fd3f4;
}

.bg-rgba-dark-1 {
    background-color: rgba(0,0,0,.3);
}

.bg-gradient-1 {
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}
// == END Color ==

// == Position ==
.t-0 {
    top: 0;
}
// .t-40px {
//   top: 40px;
// }
.t-50px {
    top: 50px;
}

.t-55px {
    top: 55px;
}
.t-100px {
  top: 100px;
}

.t-100 {
    top: 100% !important;
}

.l-0 {
    left: 0;
}

.l-auto {
    left: auto !important;
}

.r-0 {
    right: 0 !important;
}

.b-0 {
    bottom: 0;
}

// Calc with metronic subheader fix
.t-subheadfix {
    top: 119px;
}

.position-full {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.inset-0 {
    inset: 0;
}
// == END Position ==

.cpoin {
    cursor: pointer !important;
}

.cauto {
    cursor: auto !important;
}

.cwait {
    cursor: wait !important;
}

.ant-spin, // antd <Spin />
.cprogress {
    cursor: progress !important;
}

.cdef {
    cursor: default;
}

.cinherit {
    cursor: inherit !important;
}

.chelp {
    cursor: help !important;
}
// fieldset[disabled],
// fieldset[disabled] *,// OPTION
.cnotallow {
    cursor: not-allowed !important;
}

// *.ns-resize,
// html.ns-resize *{
//   cursor:ns-resize !important;
// }
// *.ew-resize,
// html.ew-resize *{
//   cursor:ew-resize !important;
// }

// html.row-resize *,
// html.row-resize body:before,
.row-resize {
    cursor: row-resize !important;
}

// html.col-resize *,
// html.col-resize body:before,
.col-resize {
    cursor: col-resize !important;
}

// html.row-resize *,
// html.col-resize * {
//   pointer-events: none !important;
// }

// html.row-resize .Resizer:active,
// html.col-resize .Resizer:active {
//   pointer-events: auto !important;
// }

.cgrab {
    cursor: -webkit-grab !important;
    cursor: grab !important;
}

.react-flow__node:active,
.cgrabbing {
    cursor: -webkit-grabbing !important;
    cursor: grabbing !important;
}

.ceresize {
    cursor: e-resize !important;
}

.ccell {
    cursor: cell;
}
// == END Cursor ==

// == XUL ==
// .scroll-smooth {
//   scroll-behavior: smooth;
// }

.zi-1 {
    z-index: 1 !important;
}

.zi-2 {
    z-index: 2;
}

.zi-3 {
    z-index: 3;
}

.zi-4 {
    z-index: 4;
}

.zi-5 {
    z-index: 5;
}
// .zi-6 {
//   z-index: 6;
// }
.zi-1001 {
    z-index: 1001;
}

.zi-1021 {
    z-index: 1021;
}

.resizeX,
.react-draggable-dragging,
// antd
.ant-popover, // OPTION
.ant-message,
.ant-notification,
.ant-modal-mask,
.ant-modal-wrap,
.zi-upModal {
    z-index: 1054 !important; // 1051
}

.opacity-1 {
    opacity: 1 !important;
}

// fonts:
// === ICON SIZE With font-size ===
.text-sm {
    font-size: .85rem; // 0.75rem | 0.875rem
}

.fs-0 {
    font-size: 0;
}

.fs-14px {
    font-size: 14px;
}

.fs-100p {
    font-size: 100%;
}

.fs-h5 {
    font-size: 1.25rem !important;
}

.fs-25r {
    font-size: 2.5rem !important;
}

.fs-inherit {
    font-size: inherit !important;
}

.fw700 {
    font-weight: 700 !important;
}

// Ellipsis break line
.text-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.clamp-2 {
    -webkit-line-clamp: 2;
    // line-height: 1.375; // max-height / 2
    // max-height: 2.75rem;
}
// END Ellipsis break line

// .q.dropdown-item:not(:empty):before,
.q-mr:not(:empty):before {
    margin-right: .5rem;
}

.q-mb-2:before {
    margin-bottom: .5rem;
}

.after-ml-auto::after {
    margin-left: auto !important;
}
// Only .form-control-sm
.dd-toggle-right::after {
    position: absolute;
    top: 13px;
    right: 9px;
}

.content-no:before {
    content: none !important;
}

.before-muted:before {
    color: #B5B5C3 !important;
}

.before-fs-2:before {
    font-size: 1.5rem !important;
}

// == Shadow ==
.shadow-1 {
    box-shadow: 0 2px 2px rgba(0,0,0,.15);
}

.shadow-2 {
    box-shadow: 0 0 1px 1px rgba(0,0,0,0.14), 0 0 2px 2px rgba(0,0,0,0.098), 0 0 5px 1px rgba(0,0,0,0.084);
}

.shadow-3 {
    box-shadow: 0 0 2px 1px rgba(255,255,255,0.4);
}
/*.shadow-3{
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}*/

.shadow-4 {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
}

.shadow-5 {
    box-shadow: 0 2px 10px rgba(115, 125, 143, .2);
}

// 0 0 0 .2rem rgba(38,143,255,.5);// $btn-focus-box-shadow;
.shadow-blue {
    box-shadow: 0 0 0 .2rem rgba(38,143,255,.5); // var(--shadow-blue);
}
// == END Shadow ==

.border-top-next > * + * {
    border-top: 1px solid #ddd;
}

.d-none-empty:empty,
.d-none-next ~ * {
    display: none !important;
}

// === SCROLL ===
// .fs-kanban-scrollhover .react-kanban-board, // Kanban
.fs-scroll {
    // overflow-x: hidden;
    // scroll-behavior: smooth;// OPTION
    // overscroll-behavior: contain;
    // FOR Firefox
    scrollbar-width: thin;
    scrollbar-color: #aaa transparent;
    -webkit-overflow-scrolling: touch; // OPTION: From Bootstrap .table-responsive
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board::-webkit-scrollbar,
.fs-scroll::-webkit-scrollbar {
    width: 9px !important; // 9px is same size with firefox scrollbar-width: thin;
    height: 9px !important;
}

.fs-scroll::-webkit-scrollbar-thumb {
    background-color: #ccc; // rgba(0, 0, 0, 0.2)
}

.fs-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.fs-scroll::-webkit-scrollbar-thumb:active {
    background-color: #777;
}

.fs-scroll::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.08); //  !important
}

.scroll-eee::-webkit-scrollbar-track {
    background-color: #eee;
}

// FOR Firefox
.scroll-eee {
    scrollbar-color: #aaa #eee;
}

// FOR Firefox
// Kanban
// .fs-kanban-scrollhover .react-kanban-board:not(:hover),

.fs-scroll.scroll-hover:not(:hover) { // :not(:active)
    scrollbar-color: transparent transparent;
}

// .ant-picker-time-panel-column:not(:hover)::-webkit-scrollbar,
.fs-scroll.scroll-hide::-webkit-scrollbar {
    width: 0 !important; // 0.4rem | 0.5rem
    height: 0 !important;
}

// .ant-picker-time-panel-column:not(:hover),
.fs-scroll.scroll-hide {
    scrollbar-width: none;
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board::-webkit-scrollbar-track,
.fs-scroll.scroll-hover::-webkit-scrollbar-track {
    background-color: transparent;
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board:hover::-webkit-scrollbar-track,
.fs-scroll.scroll-hover:hover::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.08);
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board::-webkit-scrollbar-thumb,
.fs-scroll.scroll-hover::-webkit-scrollbar-thumb {
    background-color: transparent;
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board:hover::-webkit-scrollbar-thumb,
.fs-scroll.scroll-hover:hover::-webkit-scrollbar-thumb {
    background-color: #ccc;
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board::-webkit-scrollbar-thumb:hover,
.fs-scroll.scroll-hover::-webkit-scrollbar-thumb:hover {
    background-color: #aaa; // bbb
}

// Kanban
// .fs-kanban-scrollhover .react-kanban-board > div::-webkit-scrollbar-thumb:active,
// .fs-scroll.scroll-hover:active::-webkit-scrollbar-thumb,
.fs-scroll.scroll-hover::-webkit-scrollbar-thumb:active {
    background-color: #777;
}

.fs-scroll.lock {
    overflow: hidden !important;
    padding-right: 9px !important;
}

// .ovscroll-auto {
//   overscroll-behavior: auto !important;
// }

.ant-picker-time-panel-column,
.ovscroll-contain {
    overscroll-behavior: contain;
}

.scroller-x {
    flex-wrap: nowrap;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
}
// === END SCROLL ===

// // NavDrawer
// .navDrawer .ant-drawer-content {
//   background-color: rgba(255,255,255, .9);
// }

// .icon-app {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 38px;
//   height: 38px;
//   // background-color: #cee6fd;
//   color: #fff; // rgba(38, 143, 255, 0.91)
//   // border: 3px solid rgba(38,143,255,.5);
//   border: 4px solid transparent;
//   // border-radius: 50%;
// }

// // Primary
// .icon-app.primary {
//   // box-shadow: 0 0 0 .2rem #0073B9; // #00A1D6
//   border-color: #0073B9;
//   background-color: #1991DB; // #55BFE2
// }

// // Secondary
// // Dark : #0073B9
// // Light: #1991DB

// // Success
// .icon-app.success {
//   // box-shadow: 0 0 0 .2rem #07BF80;
//   border-color: #07BF80;
//   background-color: #15E29C;
// }
// // Danger
// .icon-app.danger {
//   // box-shadow: 0 0 0 .2rem #DE1C22; // rgba(38,143,255,.5)
//   border-color: #DE1C22;
//   background-color: #EE6161;
// }
// // Warning
// .icon-app.warning {
//   // box-shadow: 0 0 0 .2rem #FFBC00; // rgba(38,143,255,.5)
//   border-color: #FFBC00;
//   background-color: #F9CF5B;
// }
// // Info
// .icon-app.info {
//   // box-shadow: 0 0 0 .2rem#008197; // rgba(38,143,255,.5)
//   border-color: #008197;
//   background-color: #1EB2C9;
// }

// .btn-fs-icon.active {
//   background-color: #e2e6ea;
// }
// // END NavDrawer

// Img / video
.of-cov {
    object-fit: cover;
}

.of-con {
    object-fit: contain;
}
// END Img / video

.point-no {
    pointer-events: none; //  !important
}

.point-auto {
    pointer-events: auto !important;
}

// DEV OPTION: for draggable attribute in css
.drag-no {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    // user-drag: none;
    -moz-window-dragging: no-drag;
}

.transform-no {
    transform: none !important;
}

.i-rotate-90:before {
    transform: rotate(90deg);
}

.i-rotate-180 {
    transform: rotate(180deg);
}

// == Close / Remove button ==
.ired::before,
.xx:hover::before,
.close:hover {
    color: red;
}

// == Primary icon hover ==
.iprimary:hover::before {
    color: var(--primary);
}

.bg-hover-color {
    background-color: var(--hover-color) !important;
}

.focus-within-1 {
    outline: none !important;
}

.focus-within-1:focus-within {
    border-color: transparent;
    box-shadow: var(--box-shadow-1);
}

// Components (react-select z-index conflig)
.fs-react-select:focus,
.focus-within-zi-3:focus,
.focus-within-zi-3:focus-within,
.fs-react-select:focus-within {
    z-index: 3 !important;
}

// .focus-within-visibilty:focus-within + .invisible {
//   visibility: visible !important;
// }

// OPTION - draggable html native
// [draggable="true"]{
//   -moz-user-select: none;
//   -khtml-user-select: none;
//   -webkit-user-select: none;
//   user-select: none;
//   // Required to make elements draggable in old WebKit
//   -khtml-user-drag: element;
//   -webkit-user-drag: element;
// }
// OPTION - disabled draggable html native
// [draggable="false"]{
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -o-user-select: none;
//   user-select: none;
// }

// // MOVE TO SPECIFIC Component Name
// .hr-v,
.hr-h {
    display: flex;
    align-items: center;
    // white-space: nowrap;
    height: auto;
    line-height: 1;
    text-align: center;
    letter-spacing: .05em;
}

/*.hr-v {
  flex-direction: column;
}*/

// .hr-v::before,
// .hr-v::after,
.hr-h::before,
.hr-h::after {
    content: "";
    display: flex;
    position: relative;
    flex: 1 1;
    /*border-top: 2px solid #dee2e6;*/ // eee
}

.hr-h::before,
.hr-h::after {
    border-top: 2px solid #dee2e6;
}

.hr-h::before {
    margin-right: 9px; // 7px
}

.hr-h::after {
    margin-left: 9px;
}

.hr-h.hr-left::before {
    flex: 0 0 1.5rem;
}

/*.hr-v::before,
.hr-v::after {
  width: 2px;
  background-color: #ddd;
}

.hr-v::before {
  margin-bottom: 9px;
}
.hr-v::after {
  margin-top: 9px;
}*/

// Brand Color:
.bc-microsoft {
    background-color: #329ffd;
}

.btn.bc-microsoft:hover {
    background-color: #0089ff;
}

// Symbols:
// ROLE ACCESS
.pie {
    display: inline-block;
    user-select: none;
}

.pie.spinner-border,
.pie:before {
    width: 18px;
    height: 18px;
}

.pie:before {
    content: "";
    display: inline-block; // block
    border-radius: 50%;
    background-color: green;
    border: 2px solid green;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
}

.pie.spinner-border:before {
    content: none;
}

// .pie.ten:before
.pie.pie-no-access:before {
    border: 2px solid #cc0d0d;
    background-color: transparent;
    background-image: linear-gradient(90deg, transparent 50%, white 50%), linear-gradient(90deg, white 50%, transparent 50%);
}

// .pie.twentyfive:before
.pie.pie-own-record:before {
    background-color: #ffca00;
    border: 2px solid #ffca00;
    background-image: linear-gradient(52.5deg, transparent 50%, white 50%), linear-gradient(127.5deg, white 50%, transparent 50%);
    /*background-image: linear-gradient(180deg, white 50%, white 70%, green 70%);*/
}

// .pie.fifty:before
.pie.pie-businessunit:before {
    background-color: #ffca00;
    border: 2px solid #ffca00;
    background-image: linear-gradient(180deg, white 50%, transparent 50%);
}

// .pie.seventyfive:before
.pie.pie-parent-child:before {
    background-image: linear-gradient(232.5deg, transparent 50%, green 50%), linear-gradient(127.5deg, white 50%, transparent 50%);
}

// .pie.onehundred:before
.pie.pie-organization:before {
    background-image: none;
}

// EVENT EFFECT:
.pie.cpoin:hover:before {
    box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.pie.cpoin:active:before {
    box-shadow: 0 0 0 0.2rem #007bff;
}

.img-frame.ant-skeleton {
    width: auto;
}

// Ratio:
.ratio-1-1 {
    aspect-ratio: 1 / 1;
}

.ratio-4-3 {
    aspect-ratio: 4 / 3;
}

.ratio-16-9 {
    aspect-ratio: 16 / 9;
}

.ratio-21-9 {
    aspect-ratio: 21 / 9;
}

// OVERRIDE button html
.fs-btn {
    display: inline-block;
    background-color: transparent;
    user-select: none;
}

.focus-shadow-1:focus {
    box-shadow: inset 0 0 0 .1rem rgba(0,123,255,.5) !important;
}

.shadow-danger:focus,
.shadow-danger {
    box-shadow: inset 0 0 0 .1rem var(--danger) !important; // 0.05rem
}

// .react-draggable-transparent-selection .fe-colResizer:active:after, // OPTION: FormEditor react-resizable
// .after-full:after,
.resizeX:after,
.fs-backdrop,
.react-draggable-dragging:after {
    content: "";
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    display: block;
}

.fs-backdrop,
.backdrop-after::after {
    // content: "";
    // display: block;
    // pointer-events: none;
    // overscroll-behavior: contain;// OPTION
    transition: background-color ease-in-out .25s;
}

.fs-backdrop,
.backdrop-after::after {
    content: "";
    display: block;
    position: fixed;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right:0;
    inset: 0;
    z-index: 1021;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
}

// .backdrop-after.after-absolute::after
.after-absolute::after {
    position: absolute;
}

// Use in <FileUploadModal multiple /> result files
.grid-64 {
    // display: grid;
    grid-template-columns: repeat(auto-fill, 6.4rem);
    grid-gap: .5rem;
    grid-auto-flow: dense;
    align-items: center;
}

// Hover style:
.hover-border-primary:hover {
    border-color: var(--primary) !important;
}

// Show children on hover parent
body:not(.isMobile) .hover-child-show:not(:hover) .hover-show {
    display: none;
}

// <Signature />
.fs-input-signature {
    padding: 0;
    background-position-y: .7rem !important; // top calc(0.375em + 0.325rem)
}

.fs-input-signature:hover {
    border-color: var(--primary);
}

// Custom color:
.bg-green-1 {
    background-color: #8bd8c0 !important;
}
//

// Font icon color:
.fi-primary:before {
    color: var(--primary);
}

.fi-orange:before {
    color: var(--orange);
}

// .nav-link.active .fi-primary:before,
// button:active .fi-orange:before,
.nav-link:active .fi-primary:before,
.nav-link:active .fi-orange:before {
    color: #fff !important; // currentColor | #fff
}
// END Font icon color:

// .i-mask {
//   --icon: "";
//   background-color: currentColor;
//   width: 20px;
//   height: 20px;
//   display: inline-block;
//   -webkit-mask: var(--icon) no-repeat;
//   -webkit-mask-size: 100% 100%;
//   mask: var(--icon) no-repeat;
//   mask-size: 100% 100%;
// }

// .print-mode *:not(.fs-print),
// .print-mode *:not(.fs-print) * {
//   display: none !important;
// }

// .print-mode .print-dom
// @media print {
//   .print-mode *:not(.fs-print) {
//     display: none !important;
//   }

//   // .fs-print {

//   // }

//   // #layout-portal ~ svg {
//   //   display: none !important;
//   // }

//   // .print-mode .fs-print * {
//   //   display: inherit;
//   // }

//   // .fe-print-wrap {
//   //   background-color: transparent !important;
//   // }
// }

@media print {
    body.fs-print-mode {
        display: block !important;
        background: #fff !important;
        height: auto !important;
        overflow: auto !important;
    }

    .fs-print-mode .modal-full-2 {
        max-width: 100% !important;
        margin: 0 !important;
        border-radius: 0 !important;
    }

    .fs-print-mode .modal-full-2 .modal-dialog {
        max-width: 100% !important;
        margin: 0;
    }

    .fs-print-mode .modal-full-2 .modal-content {
        // display: block !important;
        height: auto !important;
        box-shadow: none !important;
        border: 0 !important;
        border-radius: 0;
    }

    .fs-print-mode .modal-full-2 .modal-body {
        height: auto !important;
        overflow: auto !important;
    }

    .fs-print-mode .fs-print {
        // height: auto !important;
        flex-basis: 100% !important;
        max-width: 100% !important;
        overflow: auto !important;
        box-shadow: none !important;
    }

    // PAGE options:
    // .fs-Potrait
    // .fs-print-mode .fs-print.fs-A2 {
    //   width: 21cm;
    //   height: 29.7cm !important;
    // }

    .fs-print-mode .fs-print.fs-A3 {
        width: 29.7cm;
        height: 42cm !important;
    }

    .fs-print-mode .fs-print.fs-A3.fs-Landscape {
        width: 42cm;
        height: 29.7cm !important;
    }

    .fs-print-mode .fs-print.fs-A4 {
        width: 21cm;
        height: 29.7cm !important;
    }

    .fs-print-mode .fs-print.fs-A4.fs-Landscape {
        width: 29.7cm !important;
        height: 21cm !important;
    }

    .fs-print-mode .fs-print.fs-A5 {
        width: 14.8cm;
        height: 21cm !important;
    }

    .fs-print-mode .fs-print.fs-A5.fs-Landscape {
        width: 21cm;
        height: 14.8cm !important;
    }
}

.tabContentFieldSetupModal .field-properties-form .col-form-label {
    padding-bottom: 0;
}

.tabContentFieldSetupModal .field-properties-form .mt-3-next > * + * {
    margin-top: -1px;
}

.tabContentFieldSetupModal .field-properties-form .mt-3-next .ant-input-number.mt-1 {
    margin-top: 0 !important;
}

.tabContentFieldSetupModal .field-properties-form .mt-3-next .ant-switch {
    margin-top: 8px;
    margin-bottom: 8px;
}

.tabContentFieldSetupModal .field-properties-form .mt-3-next .ant-rate {
    margin-top: 4px;
    margin-bottom: 4px;
}

.tabContentFieldSetupModal .field-properties-form .mt-3-next .col-sm-9 > .btn-outline-primary {
    margin-top: 4px;
    margin-bottom: 4px;
}

.tabContentFieldSetupModal .field-properties-form .mt-3-next .custom-radio:first-child {
    margin-top: 8px;
}

.tabContentFieldSetupModal .field-properties-form .mt-3-next .custom-radio:last-child {
    margin-bottom: 8px;
}

.tabContentFieldSetupModal .field-properties-form .mt-3-next .ant-slider {
    margin-top: 12px !important;
    margin-bottom: 8px;
}

.tabContentFieldSetupModal .field-properties-form .mt-3-next .form-control:focus,
.tabContentFieldSetupModal .field-properties-form .mt-3-next .ant-input-number-focused {
    position: relative;
    z-index: 2;
}

.modal-open .ant-select-dropdown:not(.ant-select-dropdown-hidden),
.modal-open .ant-dropdown:not(.ant-dropdown-hidden) {
    z-index: 1052;
}

// Stimulsoft
.fs_stimulsoft #StiViewerReportPanel + div {
    position: static !important;
}

.fs_stimulsoft #StiDesignerfileMenu {
    background: #0079BD !important;
}

.fs_stimulsoft .stiJsViewerNavigatePanel {
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 1;
    background: #0079BD !important;
    font-family: Arial;
    font-size: 12px;
    color: #ffffff;
}

.fs_stimulsoft .stiDesignerToolBar,
.fs_stimulsoft .stiDesignerStatusPanel,
.fs_stimulsoft .stiDesignerToolButton_Mouse {
    background: #0079BD;
}

.fs_stimulsoft .stiDesignerToolButton > table > tr > td {
    color: #ffffff !important;
}

.fs_stimulsoft .stiDesignerToolButton > div.stiDesignerStandartTabFooter {
    background: #ffffff !important;
}

.fs_stimulsoft #StiDesignerfileButton.stiDesignerFormButtonThemeDefault {
    background: rgba(0,0,0,0.15);
    border: 1px solid #19478a;
    color: #ffffff;
}

.fs_stimulsoft .stiDesignerStatusPanelButton_Mouse > table :hover {
    background-color: transparent !important;
}
// .stiJsViewerToolBar
.fs_stimulsoft .stiJsViewerToolBarTable {
    padding-right: 2rem;
}

// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(1) img
//  tr.stiJsViewerClearAllStyles

// .fs_stimulsoft .stiJsViewerToolBarTable img,
// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td td + td,
// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td > div td + td {
//   display: none !important;
// }

// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td td:before,
// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td > div td:before {
//   -moz-osx-font-smoothing: grayscale;
//   -webkit-font-smoothing: antialiased;
//   display: inline-block;
//   font-style: normal;
//   font-feature-settings: normal;
//   font-variant: normal;
//   text-rendering: auto;
//   line-height: 1;
//   font-family: "Font Awesome 5 Pro";
//   font-weight: 900;
//   text-align: center;
//   width: 1.5em; // 1.25em
// }

// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td:nth-child(1) > div td:before {
//   content: "\f01e";
// }

// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td:nth-child(3) > div td:before {
//   content: "\f07c";
// }

// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td:nth-child(4) > div td:before {
//   content: "\f303";
// }

// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td:nth-child(4) td:before,
// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td:nth-child(5) > div td:before {
//   content: "\f065";
// }

// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td:nth-child(6) td:before,
// .fs_stimulsoft .stiJsViewerToolBarTable > tbody > tr > td:nth-child(2) > table > tbody > tr > td:nth-child(6) > div td:before {
//   content: "\f56d";
// }

// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td:nth-child(1) td:before {
//   content: "\f883";
// }

// .fs_stimulsoft .stiJsViewerDashboardElementButtonsPanel > .stiJsViewerToolBarTable > tbody > tr > td:nth-child(5) td:before {
//   content: "\f865";
// }

